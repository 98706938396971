import { FunctionComponent } from 'react';

import { styled, SvgIcon, SvgIconProps } from '@mui/material';

import GolinIcon from './icon.svg';

const Icon = styled(GolinIcon)(() => ({
  '&.MuiSvgIcon-root': {
    width: 'auto',
    height: 'auto',
  },
}));

const Golin: FunctionComponent<SvgIconProps> = (props) => (
  <SvgIcon
    component={Icon}
    width="169"
    height="96"
    viewBox="0 0 169 96"
    {...props}
  />
);

export default Golin;
